<!--
 * @Author: your name
 * @Date: 2021-01-29 14:40:12
 * @LastEditTime: 2021-01-29 15:08:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tostumid:\project\web\sxvant\src\views\tos\terms.vue
-->
<template>
  <div class="box">
    <h1>TERMS AND CONDITIONS</h1>
    <p>
      TOS LIFE is a mobile-based platform that is integrated with functions such
      as facility booking, feedback report, announcements, payment of
      maintenance fees, and all other services and functions that we may
      introduce from time to time (<b>“Platform”</b>). This Platform is owned
      and operated by us, and can be assessed by residents of the condominium
      through the TOS LIFE application (<b>“App”</b>) that you can download from
      the Apple App Store or Android Play Store (whichever is applicable).
    </p>
    <p>
      By registering for a user account (<b>“User Account”</b>) on the App, and
      accessing the Platform, you acknowledge that you have read, understand and
      agree to be bound by the terms and conditions stated herein, as may be
      updated from time to time (<b>“Terms”</b>), which form an agreement
      between you and TOS PTE. LTD. (Singapore Company Registration Number:
      201906913K) (<b>“Company”</b>), that is effective as if you had signed it.
    </p>
    <p>
      Your access and use of the App, the Platform and its contents using the
      User Account are subject to the compliance of these Terms and all
      applicable laws and regulations.
      <b
        >If you do not agree with these Terms, you are not permitted to register
        the User Account and use the App, and if you have already registered the
        User Account, you must terminate any such access or use of the User
        Account and the App immediately.</b
      >
    </p>
    <p>
      The Company reserves the right to amend and update these Terms at any time
      at its sole discretion without prior notice to you. You are advised to
      regularly review and update yourself on these Terms. Please direct any
      questions you may have about these Terms, or technical questions or
      problems with the App, to us at
      <a href="mailto:support@miraiele.com.sg">support@miraiele.com.sg</a>.
    </p>
    <p></p>
    <h2>1. PERMITTED USERS</h2>
    <li>
      1.1.Only persons who are at least 21 years of age may register for and use
      the User Account. By registering and using the User Account, you represent
      and warrant to the Company that:
    </li>
    <p>
      (a)you are at least 21 years of age; or
    </p>
    <p>
      (b)you are accessing the App with the consent and under the supervision of
      a responsible adult (at least 21 years of age) who agrees to take
      responsibility for all your actions.
    </p>

    <h2>2.REGISTRATION OF USER ACCOUNT</h2>
    <li>
      2.1.Each person is only allowed to register ONE USER ACCOUNT, and in the
      event that you register for or use more than one User Account, the Company
      may terminate all your User Accounts for cause and subsequently bar or
      prohibit you from further using the App.
    </li>

    <li>2.2.You may only register for the User Account through the App.</li>

    <li>
      2.3.You may not register for or use a User Account if you (a) have
      previously been barred by the Company from using the App; (b) are
      accessing it from a location which the Company has been barred from using
      the App; or (c) are intending to use it for any non-permitted means.
    </li>

    <li>
      2.4.You will be asked to create a password when registering for the User
      Account. In order to prevent fraud or theft of your User Account, you
      should:
    </li>
    <p>(a)create and use a password of sufficient length and complexity;</p>
    <p>
      (b)keep your password confidential and safe, and not disclose it with or
      to anyone;
    </p>
    <p>(c)not use your password anywhere else other than at the App;</p>
    <p>(d)update your password from time to time; and</p>
    <p>
      (e)change your password without undue delay if you suspect that your
      password has been compromised.
    </p>

    <li>
      2.5.If the Company has reason to believe that there is likely to be or
      there is a breach of security or misuse of your User Account, the Company
      may give notice to you to require you to change your password or may
      suspend your User Account without prior warning.
    </li>
    <li>
      2.6.In registering the User Account, you represent and warrant to the
      Company that:
    </li>
    <p>(a)you have read, understand and agree to these Terms;</p>
    <p>
      (b)the information that you have provided to the Company for the purposes
      of registering the User Account is true and accurate in all aspects, and
      you will inform the Company without undue delay of any changes to the
      information that you have provided by updating your personal details
      through the App; and
    </p>
    <p>
      (c)you have not impersonated any person or falsely state any information
      provided to the Company for registering the User Account.
    </p>

    <h2>3.FUNCTIONS OF THE APP</h2>
    <li>
      3.1.Upon the successful registration of the User Account, you may access
      the App using the User Account to do the following:
    </li>
    <p>(a)view, amend and change your personal data;</p>
    <p>
      (b)provide you with the products and/or services to be made available by
      the Company on the App (“Products and/or Services”);
    </p>
    <p>
      (c)make reservations, purchases and/or payment of any Products and/or
      Services;
    </p>

    <p>(d)terminate and close your User Account; and/or</p>
    <p>
      (e)perform any other function that the Company may permit from time to
      time.
    </p>

    <h2>4.RESTRICTIONS OF USE OF THE APP</h2>
    <li>
      4.1.You are solely responsible for all acts and actions carried out
      through your User Account on the App, and you agree that you are not
      allowed to:
    </li>
    <p>
      (a)access the App by any means other than using a standard web browser or
      mobile device;
    </p>
    <p>
      (b)attempt to gain unauthorised access to or otherwise interfere or
      disrupt the App in any way;
    </p>
    <p>
      (c)sell, rent, lease, lend, redistribute, sublicense or make commercial
      use of the App or any content;
    </p>
    <p>
      (d)copy, reverse engineer, decompile, disassemble or attempt to discover
      the source code of the App;
    </p>
    <p>
      (e)reproduce or disseminate the App or any part thereof in any way not
      expressly permitted by these Terms, or which use, reproduction or
      dissemination is prohibited by law;
    </p>
    <p>
      (f)modify, alter or otherwise make any derivative uses of the App or any
      portion thereof;
    </p>
    <p>
      (g)remove, alter, or obscure any acknowledgement, credit, copyright,
      trademark, other proprietary rights, or other legal notice included with
      the App;
    </p>
    <p>
      (h)use any data mining, robot, or similar data gathering or extraction
      method on the App;
    </p>
    <p>
      (i)download (other than the page caching) any portion of the App, except
      as expressly permitted;
    </p>
    <p>
      (j)use the User Account and/or the App for any illegal or unauthorised
      purpose or engage in, encourage, or promote any illegal activity, or any
      activity that violates these Terms or any other rules or polices
      established from time to time by the Company;
    </p>
    <p>
      (k)attempt to indicate in any manner that you have a relationship with the
      Company or that the Company has endorsed you or any products or services
      for any purpose;
    </p>
    <p>
      (l)do any acts or things that would result in any interference with the
      other users’ enjoyment of the App;
    </p>
    <p>
      (m)do any acts or things that would result in inconvenience being caused
      to the Company; and
    </p>
    <p>(n)use the App for anything other than its intended purpose.</p>

    <li>
      4.2.Any use of the App other than as specifically authorised herein
      without the prior written permission of the Company is strictly prohibited
      and will result in a suspension or termination of the User Account and
      your right to access and use the App.
    </li>
    <li>
      4.3.Your use of the App is entirely at your own risk and to the fullest
      extent permitted by law, the Company will not be held liable in any way
      for any loss or damage caused by content posted on the App.
    </li>
    <li>
      If you suspect that another person has taken over control of your User
      Account and you are no longer able to access it, you must notify the
      Company through any available means 4.4.immediately. Upon receipt of such
      notification, the Company may suspend the relevant User Account pending
      the outcome of further investigations. You will continue to be fully
      responsible and liable for any act taken through your User Account until
      such date and time when the Company receives your notification.
    </li>

    <h2>5.MONITORING OF USER ACCOUNT; MAINTENANCE OF THE APP</h2>
    <li>
      5.1.You agree that the Company has the right, but shall not be obliged to:
    </li>
    <p>
      (a)monitor, screen or otherwise control your activity of the App through
      the User Account;
    </p>
    <p>
      (b)investigate any violation of these Terms and may take any action it
      deems appropriate;
    </p>
    <p>
      (c)report any activity it suspects to be in violation of any applicable
      law, statute or regulation to the appropriate authorities, and to
      co-operate with such authorities; and/or
    </p>
    <p>
      (d)to request any information from you in connection with your use of the
      User Account and the App at any time, whether for confirmation,
      verification or otherwise.
    </p>
    <li>
      5.2.The Company may also, from time to time and without giving any reason
      or prior notice, suspend access to your User Account or the App for
      maintenance, upgrading, administrative work, or other similar works, and
      the Company shall not be liable if such maintenance, upgrading,
      administrative work, or other similar works prevents you from accessing
      the User Account or the App.
    </li>

    <h2>6.PRIVACY POLICY</h2>
    <li>
      6.1.The Company’s Privacy Policy is hereby incorporated by reference, and
      you represent and warrant that you have read, understand, agree, and give
      all required consents under the Privacy Policy regarding the Company’s
      collection, use, and disclosure of your personal data.
    </li>
    <li>
      6.2.Subject to paragraph 6.3, the Company will not share your personal
      data with any third party for any purpose without your express consent,
      and any use of your personal data will be as described in the Privacy
      Policy.
    </li>
    <li>
      6.3.If the Company has reasonable grounds to believe that the User Account
      is in breach of any of these Terms, the Company reserves the right, in its
      sole and absolute discretion, to cooperate fully with governmental
      authorities in the investigation of any potential wrongdoing. The Company
      may also disclose any personal data if requested by a government or law
      enforcement body or as a result of a subpoena or other legal action. The
      Company shall not be liable for damages arising from such disclosure, and
      you agree not to bring any action or claim against the Company for such
      disclosure.
    </li>

    <h2>7.INTELLECTUAL PROPERTY</h2>
    <li>
      All content on the App are and remain at all times the property of the
      Company, including all logos, design and layout, pictures and
      descriptions, and all other designs, text, graphics, pictures,
      information, data, software, sound files, or other files available on the
      App, and may not be used or imitated without the express authorisation of
      the owner of such content.
    </li>
    <li>
      7.2.Unless expressly stated by the Company, nothing in these Terms shall
      be construed as conferring any license to intellectual property rights,
      whether by estoppel, implication or otherwise. Any license granted is
      revocable at any time.
    </li>

    <h2>8.TERMINATION</h2>
    <li>
      8.1.Your User Account may be closed or terminated under the following
      circumstances:
    </li>
    <p>(a)voluntarily by you at any time; and/or</p>
    <p>
      (b)by the Company, in its sole discretion, immediately and without notice
      suspend for any reason, including any breach by you of these Terms and/or
      any applicable laws and regulations.
    </p>
    <li>
      8.2.The closure or termination of your User Account does not affect any
      accrued right the Company may have against you or liability you may bear
      towards the Company.
    </li>

    <h2>9.THIRD-PARTY WEBSITES</h2>
    <li>
      9.1.The Company may provide links to third-party websites, apps, mobile
      services or other third-party services (“Third-Party Service”) and may
      also display, link to or otherwise make available third-party content,
      data, information, events, apps or materials (“Third-Party Material”) on
      the App.
    </li>
    <li>
      9.2.The Company does not endorse or control, and makes no representations
      or warranties of any kind, regarding any Third-Party Service or
      Third-Party Material including the content, accuracy, quality, nature,
      appropriateness, decency, functionality, performance, reliability,
      completeness, timeliness, validity, safety, legality or any other aspect
      thereof.
    </li>
    <li>
      9.3.Your use of any Third-Party Service or Third-Party Material is
      entirely at your own risk. The Company is not responsible for any issues,
      legal or otherwise, that may result from your use of the Third-Party
      Service or Third-Party Material, including any loss, damage, or harm of
      any sort incurred as a result of your use of Third-Party Service or
      Third-Party Material.
    </li>

    <h2>10.DISCLAIMER AND INDEMNITY</h2>
    <li>
      10.1.The Company provides the services on the App on an “as-is” and “as
      available” basis.
    </li>

    <li>
      10.2.Your use of any material on the App, including any Third-Party
      Service or Third-Party Material, is entirely at your own sole risk. To the
      fullest extent permitted by law, the Company is not responsible for any
      issue that may result from your use of the App, including any loss,
      damage, or harm of any sort incurred.
    </li>
    <li>
      To the fullest extent permitted by law, the Company disclaims any and all
      warranties and representations (express, implied, oral, or written) with
      respect to the App. In particular, the Company does not warrant that the
      functions contained on the App will be accurate or meet your requirements,
      that the operation of the services will be uninterrupted or error-free, or
      that any defects in the services will be corrected.
    </li>
    <li>
      10.4.You shall indemnify, defend, and hold harmless the Company and each
      of its officers, directors, employees, agents, and others acting in
      concert with us from and against any loss, liability, claim, demand, cost
      and expense, including without limitation reasonable legal costs, due to
      or arising out of, or in connection with:
    </li>
    <p>
      (a)your use of or reliance on the App (including a User Account), whether
      negligent or otherwise;
    </p>
    <p>
      (b)your use or reliance on any links to third party websites that may be
      found on the App;
    </p>
    <p>
      (c)your breach of these Terms and/or any applicable laws and regulations;
      and/or
    </p>
    <p>
      (d)any errors, omissions and/or inaccuracies whatsoever or of any
      information set out in the App.
    </p>

    <h2>11.REPRESENTATIONS AND WARRANTIES</h2>
    <li>11.1.You represent and warrant to the Company that:</li>
    <p>
      (a)you have the full capacity and authority and all necessary licenses,
      permits, and consents to enter into these Terms and any other document to
      be entered into by you hereunder or in conjunction with these Terms;
    </p>
    <p>
      (b)these Terms and each document to be executed pursuant to, under it, and
      in conjunction with it constitute valid and binding obligations on you and
      (where relevant) will bind your personal representatives, successors, and
      assigns in accordance with its respective terms; and
    </p>
    <p>(c)the performance of your obligations under these Terms does not:</p>

    <p>
      (i)result in a breach of, or constitute a default under, any instrument,
      agreement or arrangement to which he is a party or by which you are bound;
      or
    </p>
    <p>
      (ii)result in a breach of any order, judgment, or decree of any court or
      governmental agency to which you are a party or by which you are bound.
    </p>

    <h2>12.GENERAL</h2>
    <li>
      12.1.Unless otherwise provided under these Terms, the provisions of these
      Terms and the Company’s rights and remedies under these Terms are
      cumulative and are without prejudice and in addition to any rights or
      remedies the Company may have in law or in equity, and no exercise by the
      Company of any one right or remedy under these Terms, or at law or in
      equity, shall operate so as to hinder or prevent the Company’s exercise of
      any other such right or remedy as at law or in equity.
    </li>
    <li>
      12.2.The Company’s failure to enforce these Terms shall not constitute a
      waiver of these Terms, and such failure shall not affect the right of the
      Company to enforce these Terms in the future.
    </li>
    <li>
      12.3.If at any time any provision of these Terms shall be or shall become
      illegal, invalid, or unenforceable in any respect, the legality, validity,
      and enforceability of the remaining provisions of these Terms shall not be
      affected or impaired thereby, and shall continue in force as if such
      illegal, invalid, or unenforceable provision was severed from these Terms.
    </li>
    <li>
      12.4.A person who is not a party to these Terms shall have no right under
      the Contracts (Rights of Third Parties) Act, Chapter 53B of Singapore or
      any similar legislation in any jurisdiction to enforce any term of these
      Terms.
    </li>
    <li>
      12.5.The use of the User Account and/or the App and these Terms shall be
      governed by and construed in accordance with Singapore law and you hereby
      submit to the non-exclusive jurisdiction of the Singapore courts.
    </li>
  </div>
</template>

<script>
export default {
  name: "tosTerms",
  data() {
    return {};
  },
};
</script>

<style scoped>
.box {
  min-height: 100vh;
  border: 1px solid #f0f0f0;
  padding: 10px 16px 20px;
  word-break: break-word;
  line-height: 1.6;
  color: #121212;
  box-sizing: border-box;
  text-align: left;
}
.box h1,
.box h2 {
  clear: left;
  margin-top: 1em;
  margin-bottom: 1.16667em;
  font-size: 38px;
  line-height: 1.75;
  font-weight: 600;
  text-align: center;
}
.box h2 {
  font-size: 32px;
  text-align: left;
}
.box li {
  margin-top: 1em;
  margin-bottom: 1.16667em;
  font-size: 28px;
  color: #333;
  list-style: none;
}
.box p {
  margin: 1em 0;
  line-height: 1.75;
  font-size: 28px;
  text-indent: 2em;
  color: #555;
}
.box p a {
  color: #1890ff;
}
.box p b {
  font-weight: 600;
  color: #000;
}
</style>
